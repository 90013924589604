import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import AllProjects from "../components/projects/allProjects";

import INFO from "../data/user";
import SEO from "../data/seo";

import "./styles/projects.css";

const Projects = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find((item) => item.page === "projects");

	return (
		<React.Fragment>
			<Helmet>
				<title>{`Projects | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content">
				<NavBar active="projects" />
				<div className="content-wrapper">
					<div className="projects-logo-container">
						<div className="projects-logo">
							<Logo width={46} />
						</div>
					</div>
					<div className="projects-container">
						<div className="title projects-title">
						A Journey Through Full Stack Development and Data
						</div>

						<div className="subtitle projects-subtitle">
						<p>Embark on a tour of my digital craftsmanship. At the core of this space is my portfolio website - a full stack adventure marrying backend, frontend, and data analytics to display my professional evolution. Below are the keystones of my journey:</p>
						<ul>
							<li><strong>Full Stack Webapp Template:</strong> A comprehensive template demonstrating the monolithic architecture and full stack development.</li>
							<li><strong>Geospatial Analysis:</strong> My canvas for geospatial storytelling, displaying a passion for transforming data into simple spatial representations.</li>
							<li><strong>Data Engineering Project:</strong> Featuring my rigorous MIT xPRO experience, manifesting deep knowledge in data engineering through an intricate Apache Kafka project.</li>
						</ul>
						<p>These projects encapsulate my path: a blend of structure, aesthetics, and analytics. Take a peek, get inspired, and let's connect the dots of creativity and functionality together.</p>

						</div>

						<div className="projects-list">
							<AllProjects />
						</div>
					</div>
					<div className="page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Projects;
