import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';


import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import INFO from "../data/user";
import "./styles/readArticle.css";


import DOMPurify from 'dompurify';


const ArticleStyle = styled.div`
    // Add your article styles here
`;

const ReadArticle = () => {
    const navigate = useNavigate();
    let { slug } = useParams();
    const [article, setArticle] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
        fetch(`https://api.northwestwind.org/articles/${slug}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setArticle(data);
            })
            .catch(error => {
                console.error('Fetch error:', error);
            });
    }, [slug]);

    const renderArticleContent = (htmlContent) => {
        // Split the content by the pre tags while keeping them in the results
        const parts = htmlContent.split(/(<pre class="language-[\w-]+"><code>[\s\S]*?<\/code><\/pre>)/);
    
        return parts.map((part, index) => {
            // Check if the part is a code block
            if (part.startsWith('<pre')) {
                // Extract language and code from the part
                const matches = part.match(/<pre class="language-(\w+)"><code>([\s\S]*?)<\/code><\/pre>/);
                if (matches) {
                    const language = matches[1];
                    const code = matches[2];
                    // Return a SyntaxHighlighter component for code block
                    return (
                        <SyntaxHighlighter key={`code-${index}`} language={language} style={dark}>
                            {code}
                        </SyntaxHighlighter>
                    );
                }
            }
            
            // For non-code parts, return a div with dangerouslySetInnerHTML
            return <div key={`text-${index}`} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(part) }} />;
        });
    };

    if (!article) {
        return <div>Loading...</div>;
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${article.title} | ${INFO.main.title}`}</title>
                <meta name="description" content={article.description} />
                <meta name="keywords" content={article.keywords.join(", ")} />
            </Helmet>
            <div className="page-content">
                <NavBar />

                <div className="content-wrapper">
                    <div className="read-article-logo-container">
                        <div className="read-article-logo">
                            <Logo width={46} />
                        </div>
                    </div>

                    <div className="read-article-container">
                        <div className="read-article-back">
                            <img
                                src="../back-button.png"
                                alt="back"
                                className="read-article-back-button"
                                onClick={() => navigate(-1)}
                            />
                        </div>

                        <div className="read-article-wrapper">
                            <div className="read-article-date-container">
                                <div className="read-article-date">
                                    {article.date}
                                </div>
                            </div>

                            <div className="title read-article-title">
                                {article.title}
                            </div>

                            <div className="read-article-body">
                                <ArticleStyle>
                                    {renderArticleContent(article.body)}
                                </ArticleStyle>
                            </div>
                        </div>
                    </div>

                    <div className="page-footer">
                        <Footer />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ReadArticle;

