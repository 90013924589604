import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import './style/article.css';

const Article = (props) => {
  const { date, title, description, link, keywords, body } = props;
  const keywordsContent = Array.isArray(keywords) ? keywords.join(', ') : '';


  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywordsContent} />
      </Helmet>

      <div className="article">
        <div className="article-left-side">
          <div className="article-date">{date}</div>
        </div>

        <Link to={link}>
          <div className="article-right-side">
            <div className="article-title">{title}</div>
            <div className="article-description">{description}</div>
            <div className="article-link">
              Read Article{' '}
              <FontAwesomeIcon style={{ fontSize: '10px' }} icon={faChevronRight} />
            </div>
          </div>
        </Link>

        <div className="article-body" dangerouslySetInnerHTML={{ __html: body }} />
      </div>
    </React.Fragment>
  );
};

export default Article;
