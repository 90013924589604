// src/contexts/ArticlesContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';

const ArticlesContext = createContext();

export const useArticles = () => useContext(ArticlesContext);

export const ArticlesProvider = ({ children }) => {
    const [articles, setArticles] = useState([]);

    const fetchArticles = () => {
        fetch('https://api.northwestwind.org/articles')
            .then((response) => response.json())
            .then(setArticles)
            .catch(console.error);
    };

    useEffect(() => {
        if (articles.length === 0) {
            fetchArticles();
        }
    }, [articles]);

    return (
        <ArticlesContext.Provider value={{ articles, setArticles, fetchArticles }}>
            {children}
        </ArticlesContext.Provider>
    );
};
