const SEO = [
	{
		page: "home",
		description:
			"Managing complexity in a simple way, I specialize in data integration and tech solutions in logistics operations. My expertise includes Python, SQL, ETL development, and optimizing sales pipelines through geospatial data analysis.",
		keywords: ["Maestrale", "Michele Maestrale", "North West Wind", "northwestwind","Data Integration", "Logistics Technology", "Geospatial Analytics", "ETL Development", "Python Programming", "Process Optimization", "Tech Solutions in Logistics", "Automated Systems", "Data Engineering", "Sales Pipeline Analytics"],
	},

	{
		page: "about",
		description:
			"With a background in civil and environmental engineering and data engineering, I have transitioned to focusing on data integration quality and technology solutions. My experience spans roles at Glovo, Lidl Italia, and Amazon Logistics.",
		keywords: ["Maestrale", "Michele Maestrale", "North West Wind", "northwestwind","Data Integration", "Logistics Technology", "Geospatial Analytics", "ETL Development", "Python Programming", "Process Optimization", "Tech Solutions in Logistics", "Automated Systems", "Data Engineering", "Sales Pipeline Analytics"],
	},
	{
		"page": "articles",
		"description": "Explore my thoughts on data integration, logistics operations, and tech solution development. Leveraging my experiences in various managerial roles, I delve into the realms of Generative AI, Chat GPT, and Copilot to discuss how they enhance human-centric development, problem-solving strategies, and knowledge enrichment in real-world applications.",
		"keywords": [
		  "Maestrale",
		  "Michele Maestrale",
		  "North West Wind",
		  "northwestwind",
		  "Data Integration",
		  "Logistics Technology",
		  "Geospatial Analytics",
		  "ETL Development",
		  "Python Programming",
		  "Process Optimization",
		  "Tech Solutions in Logistics",
		  "Automated Systems",
		  "Data Engineering",
		  "Sales Pipeline Analytics",
		  "Generative AI",
		  "Chat GPT",
		  "Copilot",
		  "Human-Centric Development",
		  "Problem-Solving Strategies",
		  "Knowledge Enrichment",
		  "Real-World Applications"
		]
	  },
	{
		page: "projects",
		description:
			"A showcase of my projects including the development of a last mile delivery algorithm, an integration monitoring tool, and the implementation of an automated conveyor system. These initiatives highlight my focus on cost-efficiency and operational improvement.",
		keywords: ["Maestrale", "Michele Maestrale", "North West Wind", "northwestwind","Data Integration", "Logistics Technology", "Geospatial Analytics", "ETL Development", "Python Programming", "Process Optimization", "Tech Solutions in Logistics", "Automated Systems", "Data Engineering", "Sales Pipeline Analytics"],
	},
	{
		page: "contact",
		description:
			"Interested in data-driven logistics and technological innovations? Contact me to discuss potential collaborations. My expertise in data integration and process optimization can contribute to the success of diverse projects.",
		keywords: ["Maestrale", "Michele Maestrale", "North West Wind", "northwestwind","Data Integration", "Logistics Technology", "Geospatial Analytics", "ETL Development", "Python Programming", "Process Optimization", "Tech Solutions in Logistics", "Automated Systems", "Data Engineering", "Sales Pipeline Analytics"],
	},];

export default SEO;
