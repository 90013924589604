import React from "react";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./styles/works.css";

const companies = [
	{
	  name: "Glovo",
	  imageUrl: "./glovo.png", // Make sure this path points to the Glovo logo image in your project
	  roles: [
		{
		  title: "Business Analyst - Tech Solutions",
		  duration: "Sep 2023 - Present",
		  location: "Barcelona",
		  details: "Data Integration Quality Project, Python BOT and ETL development, Geospatial Data Analytics."
		},
		{
		  title: "Data Integration Manager",
		  duration: "Aug 2022 - Sep 2023", // Specify the actual dates
		  location: "Barcelona",
		  details: "Ownership of Italian Q-commerce Data Integration, Data Analytics."
		},
		{
		  title: "Operations Manager - Logistics",
		  duration: "Jan 2022 - Aug 2022", // Specify the actual dates
		  location: "Lombardia - Piemonte Area",
		  details: "Ownership of Last Mile project, Operational Strategy."
		},
	  ],
	},
	{
	  name: "Lidl Italia",
	  imageUrl: "./lidl.png", // Make sure this path points to the Lidl logo image in your project
	  roles: [
		{
		  title: "Retail Operations Area Manager",
		  duration: "Jan 2021 - Jan 2022",
		  location: "Milano",
		  details: "Organizing store operations, Data Analytics, Meeting quality standards."
		},
	  ],
	},
	{
	  name: "Amazon Logistics",
	  imageUrl: "./amazon.png", // Make sure this path points to the Amazon logo image in your project
	  roles: [
		{
		  title: "Shift Operations Manager",
		  duration: "Aug 2019 - Jan 2021",
		  location: "Milano",
		  details: "Organizing Shift operations, Data Analytics, Automated Diverting Machine management."
		},
	  ],
	},
	// Add more companies and roles if needed
  ];
  
  const WorkItem = ({ company, roles, imageUrl }) => (
	<div className="work-item">
	  <img src={imageUrl} alt={company} className="work-image" />
	  <div className="work-details">
		<div className="work-company">{company}</div>
		{roles.map((role, index) => (
		  <div key={index} className="work-role">
			<div className="role-title">{role.title}</div>
			<div className="role-duration">{role.duration}</div>
		  </div>
		))}
	  </div>
	</div>
  );
  
  const Works = () => {
    return (
      <div className="works-container">
        <div className="works-header">
          <FontAwesomeIcon icon={faBriefcase} size="lg" />
          <h2>Work</h2>
        </div>
        {companies.map((item, index) => (
          <WorkItem key={index} company={item.name} roles={item.roles} imageUrl={item.imageUrl} />
        ))}
      </div>
    );
  };
  
  export default Works;